import { navigate } from 'gatsby'
import { formatDate } from 'goodpath-common'
import React, { useEffect, useState, useCallback } from 'react'
import { NoIndex } from '../components/NoIndex'
import WelcomeBack from '../components/ReEnrollment/WelcomeBack'
import { useUserContext } from '../contexts/User/userContext'
import api from '../api'
import Loading from '../components/Program/Loading'

export default function WelcomeBackPage() {
  const [name, setName] = useState('')
  const [startedAt, setStartedAt] = useState(null)
  const [endedAt, setEndedAt] = useState(null)
  const [condition, setCondition] = useState(null)
  const [proceeding, setProceeding] = useState(false)

  const { user, userInitialized, updateUser } = useUserContext()

  const onProceed = useCallback(
    async () => {
      const programId = user?.state?.programMetaData?.id
      if (!programId) return
      await api.program.terminateProgram(programId)
      setProceeding(true)
      updateUser({
        state: {
          type: '',
          subscriptionId: null,
          enrolled: false
        }
      })

      return navigate(`/select-condition`)
    },
    [user, updateUser],
  )

  useEffect(() => {
    if (!userInitialized) return
    if (user?.state?.type !== 'inactive') {
      if (proceeding) return
      navigate('/')
    }

    if (user?.firstName) setName(user.firstName)
    if (user?.state?.programMetaData) {
      const { startedAt, endedAt, condition } = user.state.programMetaData

      if (!startedAt || !endedAt) return
      setStartedAt(formatDate(startedAt, 'MM/DD/YYYY'))
      setEndedAt(formatDate(endedAt, 'MM/DD/YYYY'))
      setCondition(condition)
    }
  }, [proceeding, user, userInitialized])

  return (
    <>
      <NoIndex />
      {!userInitialized ? <Loading />
        :
        <WelcomeBack
          name={name}
          startedAt={startedAt}
          endedAt={endedAt}
          condition={condition}
          onProceed={onProceed}
        />
      }
    </>
  )
}
