import React, { useCallback, useMemo, useState } from 'react'
import { radioConditionOptions as conditionSlugToName } from 'goodpath-common'
import Button from '../common/Button'

export default function WelcomeBack({
  name,
  startedAt,
  endedAt,
  condition,
  onProceed
}: Props) {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const onClickHandler = useCallback(() => {
    setLoading(true)
    setError(null)
    onProceed()
      .catch((e) => {
        console.log(e)
        setError('Something went wrong. Please try again.')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [onProceed])

  const conditionName = useMemo(() => {
    return conditionSlugToName.find((c) => c.value === condition)?.text
  }, [condition])

  return (
    <div className="flex items-center justify-center leading-tight">
      <div className="flex flex-col max-w-xl px-10 py-16 lg:px-0">
        <div className="py-4 text-4xl bold text-primary-base">
          Welcome back, {name}!
        </div>
        <div className="pt-3 pb-8">
          Since we haven't seen you in a while, you're required to re-take your
          medical assessment. We want to know your latest symptoms to give you
          an updated program.
        </div>
        <div className="flex justify-between p-6 rounded bg-gp-violet-5">
          <div>
            <div className="pb-1 text-lg bold">{conditionName}</div>
            <div className="text-sm">Expired</div>
          </div>
          <div className="flex flex-col items-end text-sm">
            <div className="pb-1">Start date: {startedAt}</div>
            <div>End date: {endedAt}</div>
          </div>
        </div>
        <div className="pt-8 pb-4">
          <Button onClick={onClickHandler} isLoading={loading}>
            Start a new quiz
          </Button>
          {error && <div className="pt-1">{error}</div>}
        </div>
        <div className="py-4">
          For other questions regarding your program, <br /> contact this{' '}
          <a
            className="focus:outline-0"
            href="mailto:coach@goodpath.com"
            target="_blank"
            rel="noreferrer"
          >
            coach@goodpath.com
          </a>
        </div>
      </div>
    </div>
  )
}

type Props = {
  name: string
  startedAt: string
  endedAt: string
  condition: string
  onProceed: () => Promise<void>
}
